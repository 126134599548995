"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Image from "@/components/atoms/Image";
import { NavigateRouter } from "@/utils";
export default function NotFound() {
  const {
    t
  } = useTranslation();
  return <main className="relative isolate min-h-full">
      <Image src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75" alt="not-found-image" className="absolute inset-0 -z-10 h-full w-full object-cover" />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="text-base font-semibold leading-8 text-white">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-4 text-base text-white/70 sm:mt-6">
          {t("common.Page not found")}
        </p>
        <div className="mt-10 flex justify-center">
          <Link href={NavigateRouter.HOME} className="mt-4 rounded-md bg-violet-bds px-4 py-2 text-sm text-white transition-colors hover:bg-violet-400">
            {t("header.Home")}
          </Link>
        </div>
      </div>
    </main>;
}